import {Memoize} from 'typescript-memoize';
import {PatientProfileTypes} from '@matchsource/models/patient-shared';

export interface GlStringHaploidModel {
  types: string[];
  phasedTypes: string[];
}

export interface GlStringHaploidPairModel {
  one: GlStringHaploidModel;
  two: GlStringHaploidModel;
}

export interface GlStringModel {
  haploidPairs: GlStringHaploidPairModel[];
}

export type HlaMethodLetter = 's' | 'd' | '';

export interface HlaExtendedModel {
  name: string;
  type1: string;
  type2: string;
  alleleRevealAntigen1: string;
  alleleRevealAntigen2: string;
  noTypingAvailable: boolean;
  partOfGroup1: boolean;
  partOfGroup2: boolean;
  onlyPossibleAllele1: boolean;
  onlyPossibleAllele2: boolean;
  typingMethod1: string;
  typingMethod2: string;
  method: string;
  matchGrade1: string;
  matchGrade2: string;
  sign1: string;
  sign2: string;
  matchLikelihood: number;
  type1Formatted: string;
  type2Formatted: string;
  alleleRevealAntigen1Formatted: string;
  alleleRevealAntigen2Formatted: string;
  methodLetter: HlaMethodLetter;
  matchGradeSign1: string;
  matchGradeSign2: string;
  matchLikelihoodFormatted: string;
  matchGradeClass1: string;
  matchGradeClass2: string;
  typingFormatted: string;
  typing: string;
  glstring: string;
  glstringBox: GlStringModel;
  glstringInd: boolean;
  viewGlString: boolean;

  leaderValue1Formatted?: string;
  leaderValue2Formatted?: string;
  leaderValue1?: string;
  leaderValue2?: string;
  type1AntibodyMatch?: boolean;
  type2AntibodyMatch?: boolean;
  leaderTyping?: string;
  dpb1ExpressionValue1?: Dpb1ExpressionLiteral | null;
  dpb1ExpressionValue2?: Dpb1ExpressionLiteral | null;
}

export interface HlaModel {
  alleleRevealAntigen1: string;
  alleleRevealAntigen2: string;
  glstring: string;
  glstringBox: GlStringModel;
  glstringInd: boolean;
  matchGrade1: string;
  matchGrade2: string;
  matchGradeClass1: string;
  matchGradeClass2: string;
  matchGradeSign1: string;
  matchGradeSign2: string;
  matchLikelihood: number;
  matchLikelihoodFormatted: string;
  method: string;
  methodLetter: HlaMethodLetter;
  name: string;
  noTypingAvailable: boolean;
  onlyPossibleAllele1: boolean;
  onlyPossibleAllele2: boolean;
  partOfGroup1: boolean;
  partOfGroup2: boolean;
  sign1: string;
  sign2: string;
  type1: string;
  type1Formatted: string;
  type2: string;
  type2Formatted: string;
  typing: string;
  typingFormatted: string;
  typingMethod1: string;
  typingMethod2: string;
  viewGlString: boolean;
}

export enum Dpb1Expression {
  High = 'HIGH',

  Low = 'LOW',

  Unknown = 'UNKNOWN',
}

export type Dpb1ExpressionLiteral = `${Dpb1Expression}`;

export interface Dpb1ExpressionLevels {
  dpb1ExpressionValue1: Dpb1ExpressionLiteral;
  dpb1ExpressionValue2: Dpb1ExpressionLiteral;
}

export interface HlaLocusModel {
  type1: string;
  type2: string;
  typingMethod1: string;
  typingMethod2: string;
  matchGrade1: string;
  matchGrade2: string;
  sign1: string;
  sign2: string;
  hlaExprChar1: string;
  hlaExprChar2: string;
  matchLikelihood: number;
  locus?: string;
  name?: string;
  partOfGroup1?: boolean;
  partOfGroup2?: boolean;
  noTypingAvailable?: boolean;
  onlyPossibleAllele1?: boolean;
  onlyPossibleAllele2?: boolean;
  alleleRevealAntigen1?: string;
  alleleRevealAntigen2?: string;
  type1AntibodyMatch?: boolean;
  type2AntibodyMatch?: boolean;
  leaderValue1?: string;
  leaderValue2?: string;
  type1Formatted?: string;
  type2Formatted?: string;
  dpb1ExpressionValue1?: Dpb1ExpressionLiteral | null;
  dpb1ExpressionValue2?: Dpb1ExpressionLiteral | null;
}

export const SEARCH_SUM_REPORT = 'SEARCH_SUM_REPORT';

export enum ShortLocusName {
  A = 'A',

  B = 'B',

  C = 'C',

  DRB1 = 'DRB1',

  DQB1 = 'DQB1',

  DRB3 = 'DRB3',

  DRB4 = 'DRB4',

  DRB5 = 'DRB5',

  DPB1 = 'DPB1',

  DQA1 = 'DQA1',

  DPA1 = 'DPA1',
}

export interface TypingResult {
  antigen1?: string;
  antigen2?: string;
  locus?: string;
  matchType1?: string;
  matchType2?: string;
  matchGrade1?: string;
  matchGrade2?: string;
}

export interface ReferralPlanModel {
  createdBy?: string;
  createdTs?: string;
  id?: number;
  isReferredToTC?: boolean;
  notReferredReason?: 'TPU' | 'TNI' | 'PDECLINED' | 'PDECEASED' | 'OTHER';
  otherReason?: string;
  referredPlanTs?: string;
  referredTC?: string;
  updatedBy?: string;
  updatedTs?: string;
}

export interface DocumentDetailsModel {
  id?: number;
  title?: string;
  date?: string;
}

export interface NotReferredReasonModel {
  code?: 'TPU' | 'TNI' | 'PDECLINED' | 'PDECEASED' | 'OTHER';
  description?: string;
}

export interface HaploidPairModel {
  probability?: number;
  typings?: {[key: string]: TypingResult};
  xof10MatchCategory?: string;
  xof8MatchCategory?: string;
}

export class GenotypeProbability {
  percent: number;
  typingResult: TypingResult;

  static formatType(antigen: string): string {
    return (antigen || '').replace(/HLA-.+?\*/, '');
  }

  @Memoize()
  get type1(): string {
    return GenotypeProbability.formatType(this.typingResult.antigen1);
  }

  @Memoize()
  get type2(): string {
    return GenotypeProbability.formatType(this.typingResult.antigen2);
  }

  @Memoize()
  get matchGrade1(): string {
    return this.typingResult.matchGrade1;
  }

  @Memoize()
  get matchGrade2(): string {
    return this.typingResult.matchGrade2;
  }

  @Memoize()
  get type1Formatted(): string {
    return this.typingResult.antigen1;
  }

  @Memoize()
  get type2Formatted(): string {
    return this.typingResult.antigen2;
  }

  @Memoize()
  get matchGrade(): string {
    return `${this.matchGrade1 || ''}\n${this.matchGrade2 || ' '}`;
  }

  @Memoize()
  get hasMatch(): boolean {
    return (
      (this.matchGrade1 === 'A' || this.matchGrade1 === 'P') && (this.matchGrade2 === 'A' || this.matchGrade2 === 'P')
    );
  }
}

export interface HlaHistoryLocusModel {
  name: string;
  type1: string;
  type2: string;
  typingMethod1: string;
  typingMethod2: string;
  type1Formatted: string;
  type2Formatted: string;
  glstring: string;
  glstringBox: GlStringModel;
}

export type PreferredTestResultsModel = MsApp.Dictionary<HlaHistoryLocusModel> & {index?: number};

export interface HlaAntibodyAllele {
  allele?: string;
  mfiVal?: number;
}

export interface HlaAntibodyAntigen {
  antigen?: string;
  mfiVal?: number;
}

export interface HlaAntibodyProfileModel {
  alleleEntries?: {[key: string]: HlaAntibodyAllele[]};
  antigenEntries?: {[key: string]: HlaAntibodyAntigen[]};
  documentId?: number;
  enteredByFullName?: string;
  entryDate?: MsApp.DateString;
  id?: number;
  mfi?: boolean;
  name?: string;
  patientGuid?: MsApp.Guid;
  sampleDate?: MsApp.DateString;
  profileType?: PatientProfileTypes;
}

export interface SelfAntibodyModel {
  selfAlleles?: {[key: string]: string[]};
  selfAntigens?: {[key: string]: string[]};
}

export enum Dpb1ExpressionFavorability {
  Favorable = 'FAVORABLE',

  Unfavorable = 'UNFAVORABLE',

  Blank = '',
}

export enum Dpb1ExpressionMatchType {
  Match = 'Match',

  SingleMismatch = 'Single Mismatch',

  DoubleMismatch = 'Double Mismatch',

  Untyped = 'Untyped',

  Blank = '',
}

export interface Dpb1ExpressionParts {
  dpb1ExpressionMatchType: Dpb1ExpressionMatchType;

  dpb1ExpressionMatchStatus: Dpb1ExpressionFavorability;
}
export interface Patient {
  id: MsApp.Guid;
  identification: string;
  isHlaToday: boolean;
  tcInfo: PatientTcInfo;
}

export interface PatientTcInfo {
  tcId: MsApp.Guid;
}

export interface HlaMapModel {
  A?: HlaModel;
  B?: HlaModel;
  C?: HlaModel;
  DQB1?: HlaModel;
  DRB1?: HlaModel;
  DRB3?: HlaModel;
  DRB5?: HlaModel;
  DRB4?: HlaModel;
  DPB1?: HlaExtendedModel;
  DPA1?: HlaModel;
  DQA1?: HlaModel;
  index?: number;
  list?: HlaModel[];
}

export interface MatchingModel {
  alleleLevelMatchProbability6?: string;
  alleleLevelMatchProbability6List?: string[];
  alleleLevelMatchProbability8: string;
  alleleLevelMatchProbability8List: string[];
  alleleLevelMatchProbability10: string;
  alleleLevelMatchProbability10List: string[];
  ccr5: string;
  ccr5Code: string;
  dpb1TCEMatch: string;
  hla: HlaModel[];
  hlaMap: HlaMapModel;
  matchingCategory: string;
  matchingCategory_Xf8: string;
  matchingCategory_Xf10?: string;
  noTypingAvailable: boolean;
  cd34FcWeight?: number;
  tncWeight?: number;
}

export enum Locus {
  A = 'A',
  B = 'B',
  C = 'C',
  DRB1 = 'DRB1',
  DQB1 = 'DQB1',
}

export const HLA_ORDER = {
  A: 0,
  B: 1,
  C: 2,
  DRB1: 3,
  DQB1: 4,
  DPB1: 5,
  DRB3: 6,
  DRB4: 7,
  DRB5: 8,
  DQA1: 9,
  DPA1: 10,
};
export const TYPING_METHODS = {
  SER: 'SER',
  DNA: 'DNA',
};
export const REG_TEST_REASON = {
  RECRUIT: 'HLA_HISTORY.TEST_REASON_VALUES.RECRUIT',
  INITIAL: 'HLA_HISTORY.TEST_REASON_VALUES.INITIAL',
  CUSTOM: 'HLA_HISTORY.TEST_REASON_VALUES.CUSTOM',
  CONFIRM: 'HLA_HISTORY.TEST_REASON_VALUES.CONFIRM',
  DISCRES: 'HLA_HISTORY.TEST_REASON_VALUES.DISCRES',
  PROSPECT: 'HLA_HISTORY.TEST_REASON_VALUES.PROSPECT',
  QUALITY: 'HLA_HISTORY.TEST_REASON_VALUES.QUALITY',
  REGUPGRADE: 'HLA_HISTORY.TEST_REASON_VALUES.REGUPGRADE',
  STPRTNR: 'HLA_HISTORY.TEST_REASON_VALUES.STPRTNR',
  SPROJECT: 'HLA_HISTORY.TEST_REASON_VALUES.SPROJECT',
  TRASH: 'HLA_HISTORY.TEST_REASON_VALUES.TRASH',
  UNKNOWN: 'HLA_HISTORY.TEST_REASON_VALUES.UNKNOWN',
  HIGHRES: 'HLA_HISTORY.TEST_REASON_VALUES.HIGHRES',
  STORAGE: 'HLA_HISTORY.TEST_REASON_VALUES.STORAGE',
  UPDATE: 'HLA_HISTORY.TEST_REASON_VALUES.UPDATE',
  TRTRANSFER: 'HLA_HISTORY.TEST_REASON_VALUES.TRTRANSFER',
};
export const TEST_GROUP = {
  REG: REG_TEST_REASON,
};
export const RES_TEST_GROUP_NAME = 'RES';
export const RES_TEST_REASON = 'HLA_HISTORY.TEST_REASON_VALUES.NMDP_RESEARCH';
